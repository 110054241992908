import React from 'react';

import SEO from '../components/seo';

const NotFoundPage = () => (
	<>
		<SEO title="404: Not found" />
		<h1>NOT FOUND</h1>
	</>
)

export default NotFoundPage;
